import { SUPPORT_PHONE_NUMBER } from '@constants/contactUs';
import { Link, SxProps } from '@mui/material';
import { formatPhoneNumber } from '@utils/formatPhoneNumber';
import { ReactNode } from 'react';

type Props = {
  sx?: SxProps;
  children?: ReactNode;
};

export function CallUsLink({ sx = {}, children }: Props) {
  return (
    <Link href={`tel:${SUPPORT_PHONE_NUMBER}`} sx={sx}>
      {children || formatPhoneNumber(SUPPORT_PHONE_NUMBER)}
    </Link>
  );
}
